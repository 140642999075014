import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";


export const universitysSlice = createSlice({
  name: "universitys",
  initialState: {
    universitys: [],
    filteredUniversitys: [],
    loading: false,
    message: "",
    totalUniversity: 0,
    currentUniversity: { },
    allUniversity: []
  },
  reducers: {
    setUniversitys (state, action) {
      state.universitys = action.payload;
    },
    setMessage (state, action) {
      state.message = action.payload;
    },
    setLoading (state, action) {
      state.loading = action.payload
    },
    setTotalCount (state, action) {
      state.totalUniversity = action.payload;
    },
    setCurrentUniversity (state, action) {
      state.currentUniversity = action.payload
    },
    setAllUniversity (state, action) {
      state.allUniversity = action.payload;
    },
    setFilteredUniversity (state, action) {
      state.filteredUniversitys = action.payload;
    }
  }
})

export const { setUniversitys, setMessage, setLoading, setTotalCount, setCurrentUniversity, setAllUniversity, setFilteredUniversity } = universitysSlice.actions;
export default universitysSlice.reducer;

export const getUniversitys = (sort = 'new') => async (dispatch) => {
  try {
      const result = await axios.get("/api/university", { sort });
      dispatch(setUniversitys(result.data.data));
      // dispatch(setMessage("Successfully Loaded"))
  } catch (error) {
      dispatch(setMessage("Error"))
    
  }
}

export const insertUniversitys = ( university ) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await axios.post("/api/university", university);
    toast.success(result.data.message)
    if (result.data.success) {
      dispatch(getUniversitys());
    }
    dispatch(setMessage(result.data.message))
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setMessage("Error"))
  }
}

export const deleteUniversity = (id) => async (dispatch) => {
  try {
      dispatch(setLoading(true));
      const result = await axios.delete(`/api/university/${id}`);
      toast.success(result.data.message)
      if (result.data.success) {
          dispatch(getUniversitys());
      }
      dispatch(setMessage(result.data.message));
      dispatch(setLoading(false));
  } catch (error) {
      dispatch(setMessage("Error"))
  }
}

export const getTotalUniversity = () => async (dispatch) => {
  try {
    const result = await axios.get('/api/university/totalCount');
    const universities = result.data.data;
    dispatch(setTotalCount(universities.length))
    dispatch(setAllUniversity(universities));
  } catch (error) {
    console.log(error)
  }
}

export const getIndividualUniversity = (id) => async (dispatch) => {
  try {
    const result = await axios.get(`/api/university/indi/${id}`);
    dispatch(setCurrentUniversity(result.data.data))
  } catch (error) {
    console.log(error);
  }
}

export const searchUniversity = (title) => async (dispatch) => {
  try {
    const result = await axios.get(`/api/university/search`,{ params: { title }});
    dispatch(setFilteredUniversity(result.data.data))
  } catch (error) {
    console.log(error)
  }
}