// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
    user: icon('ic_user'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
};

const navConfig = [
    {
        items: [
            { title: "Dashboard", path: PATH_DASHBOARD.dashboard, icon: ICONS.dashboard }
        ]
    },
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'DOC MANAGEMENT',
        items: [
            {
                title: "University", 
                path: PATH_DASHBOARD.universitys.root,
                icon: ICONS.dashboard,
                children: [
                    { title: 'New University', path: PATH_DASHBOARD.universitys.new },
                    { title: 'Contents', path: PATH_DASHBOARD.universitys.root }
                ]
            },
            {
                title: "Course",
                path: PATH_DASHBOARD.courses.root, 
                icon: ICONS.dashboard,
                children: [
                    { title: 'New Course', path: PATH_DASHBOARD.courses.new },
                    { title: 'Contents', path: PATH_DASHBOARD.courses.root }
                ]
            },
            { 
                title: "Task", 
                path: PATH_DASHBOARD.tasks.root, 
                icon: ICONS.dashboard,
                children: [
                    { title: 'New Task', path: PATH_DASHBOARD.tasks.new },
                    { title: 'Contents', path: PATH_DASHBOARD.tasks.root }
                ] 
            },
            {
                title: "Download",
                path: PATH_DASHBOARD.download,
                icon: ICONS.dashboard
            },
            {
                title: 'Assets',
                path: PATH_DASHBOARD.assets.root,
                icon: ICONS.dashboard,
                children: [
                    { title: 'Pinterest', path: PATH_DASHBOARD.assets.pinterest },
                    { title: 'Yumpu', path: PATH_DASHBOARD.assets.yumpu }
                ]
                
            }
        ],
    },
    {
        subheader: 'BLOG MANAGEMENT',
        items: [
            { title: "Blogs", path: PATH_DASHBOARD.blogs.root, icon: ICONS.dashboard },
        ]
    },
    {
        subheader: 'CONTENT MANAGEMENT',
        items: [
            { title: "contents", path: PATH_DASHBOARD.contents.root, icon: ICONS.dashboard },
        ]
    },
    {
        subheader: "Setting",
        items: [
            { title: "Profile", path: PATH_DASHBOARD.profile, icon: ICONS.profile }
        ]
    }
];

export default navConfig;
