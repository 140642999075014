import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
    HomePage,
    Page404,
    LoginPage,
    RegisterPage,
    ResetPasswordPage,
    NewPasswordPage,

    UniversityPage,
    AddCoursePage,
    CoursePage,
    DashBoardPage,
    ProfilePage,
    UniversityEditPage,
    UniversityNewPage,
    CourseEditPage,
    CourseNewPage,
    TaskPage,
    TaskEditPage,
    TaskCoursePage,
    TaskNewPage,
    BlogPostsPage,
    BlogNewPostPage,
    BlogPostPage,
    ContentPostsPage,
    ContentNewPostPage,
    ContentPostPage,
    CourseReviewPage,
    TaskReviewPage,
    CourseUniversityPage,
    DownloadPage,
    PinterestPage,
    YumpuPage
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/',
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },
                {
                    path: "register",
                    element: (
                        <GuestGuard>
                            <RegisterPage />
                        </GuestGuard>
                    )
                },
                {
                    element: <CompactLayout />,
                    children: [
                        { path: 'reset-password', element: <ResetPasswordPage /> },
                        { path: 'new-password', element: <NewPasswordPage/> }
                    ],
                },
            ],
        },
        {
            path: '/admin',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: "dashboard", element: <DashBoardPage /> },
                { path: "universitys", element: <UniversityPage /> },
                { path: "universitys/:id", element: <UniversityEditPage /> },
                { path: "universitys/new", element: <UniversityNewPage /> },
                { path: "universitys/addCourse/:id", element: <AddCoursePage /> },
                { path: "courses", element: <CoursePage /> },
                { path: "courses/:perPage/:pageNumber", element: <CoursePage /> },
                { path: "courses/:id", element: <CourseEditPage /> },
                { path: "courses/new", element: <CourseNewPage /> },
                { path: "courses/review/:id", element: <CourseReviewPage /> },
                { path: "courses/university/:universityId", element: <CourseUniversityPage /> },
                { path: 'tasks', element: <TaskPage /> },
                { path: 'tasks/:id', element: <TaskEditPage /> },
                { path: 'tasks/:perPage/:pageNumber', element: <TaskPage /> },
                { path: 'tasks/new', element: <TaskNewPage /> },
                { path: 'tasks/review/:id', element: <TaskReviewPage /> },
                { path: 'tasks/course/:courseId', element: <TaskCoursePage /> },
                { path: "profile", element: <ProfilePage /> },
                { path: "blogs", element: <BlogPostsPage /> },
                { path: "blogs/new", element: <BlogNewPostPage /> },
                { path: "blogs/:title", element: <BlogPostPage /> },
                { path: "contents", element: <ContentPostsPage /> },
                { path: "contents/new", element: <ContentNewPostPage /> },
                { path: "contents/:title", element: <ContentPostPage /> },
                { path: "download", element: <DownloadPage/> },
                { path: 'assets/pinterest', element: <PinterestPage/> },
                { path: "assets/pinterest/:perPage/:pageNumber", element: <PinterestPage /> },
                { path: 'assets/yumpu', element: <YumpuPage/> },
                { path: "assets/yumpu/:perPage/:pageNumber", element: <YumpuPage /> },

            ],
        },
        {
            element: <CompactLayout />,
            children: [{ path: '404', element: <Page404 /> }],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}
