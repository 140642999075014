// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/admin';
const ROOTS_RESOURCE = "/universities";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: "/register",
  newPassword: '/new-password',
  resetPassword: '/reset-password'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, "/dashboard"),
  universitys: {
    root: path(ROOTS_DASHBOARD, "/universitys"),
    edit: (name) => path(ROOTS_DASHBOARD, `/universitys/${name}`),
    new: path(ROOTS_DASHBOARD, "/universitys/new"),
    course: (name) => path(ROOTS_DASHBOARD, `/universitys/addCourse/${name}`)
  }, 
  courses: {
    root: path(ROOTS_DASHBOARD, "/courses"),
    edit: (name) => path(ROOTS_DASHBOARD, `/courses/${name}`),
    new: path(ROOTS_DASHBOARD, "/courses/new"),
    review: (name) => path(ROOTS_DASHBOARD, `/courses/review/${name}`),
    university: (universityId) => path(ROOTS_DASHBOARD, `/courses/university/${universityId}`)
  },
  tasks: {
    root: path(ROOTS_DASHBOARD, '/tasks'),
    edit: (name) => path(ROOTS_DASHBOARD, `/tasks/${name}`),
    new: path(ROOTS_DASHBOARD, `/tasks/new`),
    course: (courseId) => path(ROOTS_DASHBOARD, `/tasks/course/${courseId}`),
    review: (name) => path(ROOTS_DASHBOARD, `/tasks/review/${name}`)
  },
  blogs: {
    new: path(ROOTS_DASHBOARD, '/blogs/new'),
    root: path(ROOTS_DASHBOARD, '/blogs'),
    view: (id) => path(ROOTS_DASHBOARD, `/blogs/${id}`)
  },
  contents: {
    new: path(ROOTS_DASHBOARD, '/contents/new'),
    root: path(ROOTS_DASHBOARD, '/contents'),
    view: (id) => path(ROOTS_DASHBOARD, `/contents/${id}`)
  },
  download: path(ROOTS_DASHBOARD, '/download'),
  profile: path(ROOTS_DASHBOARD, "/profile"),
  assets: {
    root: path(ROOTS_DASHBOARD, '/assets'),
    pinterest: path(ROOTS_DASHBOARD, '/assets/pinterest'),
    yumpu: path(ROOTS_DASHBOARD, '/assets/yumpu'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  resources: {
    root: path(ROOTS_RESOURCE, '/'),
    universitys: path(ROOTS_RESOURCE, '/'),
  },

};


export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';