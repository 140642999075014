import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";
import { HOST_API_KEY } from '../../config-global';

export const taskSlice = createSlice({
    name: "tasks",
    initialState: {
        tasks: [],
        filteredTasks: [],
        loading: false,
        message: "",
        currentTask: {},
        allTasks: [],
        totalTask: 0
    },
    reducers: {
        setTasks(state, action) {
            state.tasks = action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setCurrentTask(state, action) {
            state.currentTask = action.payload;
        },
        setAllTasks(state, action) {
            state.allTasks = action.payload;
        },
        setTotalTask(state, action) {
            state.totalTask = action.payload;
        },
        setFilteredTasks(state, action) {
            state.filteredTasks = action.payload;
        }
    }
})

export const { setTasks, setMessage, setLoading, setCurrentTask, setAllTasks, setTotalTask, setFilteredTasks } = taskSlice.actions;
export default taskSlice.reducer;

export const getTasks = (page, perPage) => async (dispatch) => {
    try {
        const sort = localStorage.getItem('taskSort');
        const result = await axios.get("/api/task", { params: { page, perPage, sort } });
        dispatch(setTasks(result.data.data));
    } catch (error) {
        console.log(error);
    }
}

export const generateTasks = (data) => async (dispatch) => {
    try {
        const result = await axios.post("/api/task/generate", data);
        toast.success(result.data.message)
        if (result.data.success) {
            dispatch(setMessage(result.data.message))
        }

    } catch (error) {
        console.log(error)
    }
}

export const insertTask = (task, courseId = '') => async (dispatch) => {
    try {
        const requestBody = task;
        const requestOption = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: task
        }
        const result = await fetch(`${HOST_API_KEY}/api/task`, requestOption).then(res => res.json());
        toast.success(result.message)
        if (result.success) {
            const page = localStorage.getItem('task_page');
            const perPage = localStorage.getItem('task_Perpage');
            if(courseId){
                dispatch(getTasksByCourse(courseId))
            }else{
                dispatch(getTasks(page, perPage));
            }
        }
        dispatch(setMessage(result.data.message))
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
    }
}

export const deleteTask = (id, courseId = '') => async (dispatch) => {
    try {
        dispatch(setLoading(true))
        const result = await axios.delete(`/api/task/${id}`);
        toast.success(result.data.message)
        if (result.data.success) {
            const page = localStorage.getItem('task_page');
            const perPage = localStorage.getItem('task_Perpage');
            if(courseId){
                dispatch(getTasksByCourse(courseId))
            }else{
                dispatch(getTasks(page, perPage));
            }
        }
        dispatch(setMessage(result.data.message));
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error)
    }
}

export const approveTask = (id, is_approved, type, courseId = '') => async (dispatch) => {
    try {
        setLoading(false);
        const response = await axios.post("/api/task/approve", { id, is_approved, type });
        toast.success(response.data.message);
        if (response.data.success) {
            const page = localStorage.getItem('task_page');
            const perPage = localStorage.getItem('task_Perpage');
            if(courseId){
                dispatch(getTasksByCourse(courseId))
            }else{
                dispatch(getTasks(page, perPage));
            }
        }
    } catch (error) {

    }
}

export const updateReview = (id, reviews, courseId = '') => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const result = await axios.post("/api/task/review", { id, reviews });
        if (result.data.success) {
            const page = localStorage.getItem('task_page');
            const perPage = localStorage.getItem('task_Perpage');
            if(courseId){
                dispatch(getTasksByCourse(courseId))
            }else{
                dispatch(getTasks(page, perPage));
            }
        }
        toast.success(result.data.message);
        dispatch(setMessage(result.data.message));
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
    }
}

export const getIndividualTask = (id) => async (dispatch) => {
    try {
        const result = await axios.get(`/api/task/indi/${id}`);
        dispatch(setCurrentTask(result.data.data))
    } catch (error) {
        console.log(error)
    }
}

export const getAllTasks = () => async (dispatch) => {
    try {
        const result = await axios.get('/api/task/totalTask');
        const allTasks = result.data.data;
        dispatch(setTotalTask(allTasks.length));
        dispatch(setAllTasks(allTasks))
    } catch (error) {
        console.log(error);
    }
}

export const searchTasksByName = (title) => async (dispatch) => {
    try {
        const result = await axios.get('/api/task/search', { params: { title } });
        dispatch(setFilteredTasks(result.data.data))
    } catch (error) {
        console.log(error)
    }
}

export const getTasksByCourse = (courseId) => async (dispatch) => {
    try {
        const sort = localStorage.getItem('taskSort');
        const result = await axios.get('/api/task/course', { params: { courseId, sort } });
        dispatch(setTasks(result.data.data));
    } catch (error) {
        console.log(error)
    }
}

export const updateAssets = (assets) => async (dispatch) => {
    try {
        const result = await axios.post('/api/task/updateAssets', { assets });
        toast.success(result.data.message)
    } catch (error) {
        toast.error('Upload Failed')
    }
}